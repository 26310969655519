<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="700"
    content-class="rounded-xl"
    overlay-color="rgba(9, 24, 73, 0.2)"
    overlay-opacity="1"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot/>
      </div>
    </template>
    <template>
      <v-card class="pa-6 position-relative" :class="[$store.state.loading ? 'overflow-y-hidden' : 'overflow-y-auto']" height="500">
        <v-card-actions>
          <v-menu content-class="rounded-lg-bottom elevation-0 width-160 bg-lgray" nudge-bottom="25">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                filled
                single-line
                height="30"
                color="transparent"
                hide-details
                :value="selectedSortType.text"
                class="select-fitler rounded-lg text-black fz-13 font-weight-medium select-dash border-none width-160 mr-3"
                dense
                readonly
              >
                <template v-slot:prepend-inner>
                  <v-sheet
                    v-bind="attrs"
                    v-on="on"
                    class="absolute"
                    elevation="0"
                    color="transparent"
                    style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
                  ></v-sheet>
                </template>
                <template v-slot:append>
                  <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-card class="rounded-lg mt-1 hidden" color="transparent" elevation="0">
              <v-card-text class="px-0 py-1">
                <v-list class="pa-0 scroll" color="transparent" style="max-height: 200px">
                  <v-row
                    v-for="(item, index) in sortTypes"
                    :key="index"
                    @click="selectedSortType = item"
                    style="min-height: 30px"
                    class="px-0 py-1 my-0 mx-2 pointer rounded-lg select-fitler-item"
                  >
                    <p class="ma-0 px-2 fz-12 text-black">{{ item.text }}</p>
                  </v-row>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-text-field
            @click.stop
            outlined
            single-line
            v-model="searchCountry"
            placeholder="Country"
            class="rounded-lg border-lightgray fz-16 mr-3"
            prepend-inner-icon="$search"
            dense
            hide-details
          />
          <v-btn
            elevation="0"
            dark
            height="30"
            width="150"
            min-width="16"
            class="rounded-lg text-capitalize text-st btn-create fz-14"
            @click="updateCountries"
          >
            Update
          </v-btn>
        </v-card-actions>
        <v-list v-for="item in filteredCountries" :key="item.id">
          <v-list-item>
            <v-list-item-avatar :rounded="'rounded-0'">
              <v-img :src="item.flagAbsoluteUrl" alt="Flag"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <div>
                  Clients Count: {{ item.clientsCount }}
                </div>
                <div>
                  Orders Sum: {{ Math.round(item.ordersSum) }}₽
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="preloader" v-if="$store.state.loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import countriesHook from '@/components/Hooks/Countries'

export default {
  name: 'CountryStatistics',
  data() {
    return {}
  },
  methods: {},
  setup() {
    const { searchCountry, countries, filteredCountries, selectedSortType, sortTypes, updateCountries } = countriesHook()
    return {
      searchCountry,
      countries,
      filteredCountries,
      selectedSortType,
      sortTypes,
      updateCountries
    }
  }
}
</script>

<style scoped lang="scss">

</style>
