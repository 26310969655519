<template>
  <v-col class="d-flex flex-column height-100 pa-0">
    <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        All information
      </span>
      <v-icon color="#010820" class="rounded-circle chevron-right" @click="$emit('closeAdditionalInfo')">mdi-chevron-right</v-icon>
    </v-row>
    <v-row class="ma-0 flex-grow-1 scroll">
      <v-col style="padding: 30px !important;">
        <p class="fz-14 mb-1 text-gray">Real name</p>
        <v-text-field
          filled
          single-line
          v-model="leadInfo.name"
          class="rounded-lg fz-14 text-black border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Company</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.company ? leadInfo.company.name : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Country</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.country ? leadInfo.country.name : 'None'"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Role in the company</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.companyRole ? leadInfo.companyRole.name : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Source</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.channel ? leadInfo.channel.name : ''"
          class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
          dense
          readonly
        >
          <template v-slot:prepend-inner>
             <v-sheet
              class="absolute pointer"
              elevation="0"
              color="transparent"
              style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
             ></v-sheet>
           </template>
           <template v-slot:append>
             <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
           </template>
        </v-text-field>
        <p class="fz-14 mb-1 text-gray">Contacts</p>
        <v-text-field
          filled
          single-line
          v-model="leadInfo.email"
          class="rounded-lg fz-14 text-black border-none font-weight-medium input-readonly-prepend-dash"
          dense
          readonly
          >
            <template v-slot:prepend-inner>
              <v-icon class="mt-1" size="16">$email</v-icon>
            </template>
          </v-text-field>
        <v-text-field
          filled
          single-line
          v-model="leadInfo.facebook"
          class="rounded-lg fz-14 text-black border-none font-weight-medium input-readonly-prepend-dash"
          dense
          readonly
          >
            <template v-slot:prepend-inner>
              <v-icon class="mt-1" size="16">$facebook</v-icon>
            </template>
          </v-text-field>
        <v-text-field
          filled
          single-line
          v-model="leadInfo.telegram"
          class="rounded-lg fz-14 text-black border-none font-weight-medium input-readonly-prepend-dash"
          dense
          readonly
          >
            <template v-slot:prepend-inner>
              <v-icon class="mt-1" size="16">$telegram</v-icon>
            </template>
          </v-text-field>
        <v-text-field
          filled
          single-line
          v-model="leadInfo.instagram"
          class="rounded-lg fz-14 text-black border-none font-weight-medium input-readonly-prepend-dash"
          dense
          readonly
          >
            <template v-slot:prepend-inner>
              <v-icon class="mt-1" size="16">$instagram</v-icon>
            </template>
          </v-text-field>
        <p class="fz-14 mb-1 text-gray">Gender</p>
        <v-text-field
            filled
            single-line
            :value="leadInfo.gender ? leadInfo.gender.label : ''"
            class="rounded-lg text-black mb-0 fz-14 font-weight-medium select-dash border-none"
            dense
            readonly
          >
            <template v-slot:prepend-inner>
               <v-sheet
                class="absolute pointer"
                elevation="0"
                color="transparent"
                style="left: 0; right: 0; top: 0; bottom: 0; z-index: 10"
               ></v-sheet>
             </template>
             <template v-slot:append>
               <v-icon size="20" style="margin: 2px" class="path-black">$chevronDown</v-icon>
             </template>
          </v-text-field>
        <p class="fz-14 mb-1 text-gray">Date of birth</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.birthDate ? formatDate(leadInfo.birthDate) : ''"
          class="rounded-lg fz-14 text-black border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1 text-gray">Location</p>
        <v-text-field
          filled
          single-line
          :value="leadInfo.location"
          class="rounded-lg fz-14 text-black border-none font-weight-medium"
          dense
          readonly
        ></v-text-field>
        <p class="fz-14 mb-1  text--secondary">First message text</p>
        <v-textarea
            filled
            v-model="leadInfo.firstMessageText"
            class="rounded-lg fz-14 border-none font-weight-medium"
            readonly
            height="200"
            no-resize
          ></v-textarea>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  props: ['leadInfo'],
  methods: {
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    }
  }
}
</script>

<style scoped>

</style>
