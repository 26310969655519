<template>
  <v-data-table
      :headers="headers"
      :items="clients"
      @click:row="getClient"
      class="elevation-0 table-vertical-line"
      :header-props="{ sortIcon: 'mdi-menu-down' }"
      :server-items-length="table.totalItems"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:header.name="">
        <v-text-field
          @click.stop
          prepend-inner-icon="$search"
          placeholder="Client name"
          v-model="queryOptions.searchName"
          outlined
          clearable
          class="table-search d-inline-block input-clear-small"
          hide-details
          dense
        ></v-text-field>
      </template>
      <template v-slot:header.company="">
        <v-text-field
          @click.stop
          prepend-inner-icon="$search"
          placeholder="Company"
          v-model="queryOptions.searchCompany"
          outlined
          clearable
          class="table-search d-inline-block input-clear-small"
          hide-details
          dense
        ></v-text-field>
      </template>
      <template v-slot:header.createdAt="">
        <span class="fz-13 fw-600 text-black">Added</span>
      </template>
      <template v-slot:header.ordersLtv="">
        <span class="fz-13 fw-600 text-black">LTV</span>
      </template>
      <template v-slot:header.ordersCount="">
        <span class="fz-13 fw-600 text-black">Orders</span>
      </template>
      <template v-slot:header.channel="">
        <v-select
          v-model="queryOptions.selectedChannelId"
          :items="sources"
          item-text="name"
          item-value="id"
          single-line
          multiple
          outlined
          class="rounded-lg fz-14 font-weight-medium inline-input border-none input-clear-small select-dash status-select"
          dense
          clearable
          item-color="#010820"
          hide-details
          hide-spin-buttons
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template v-slot:prepend-inner>
            <span style="padding-top: 5px;">Source</span>
            <v-icon style="padding-top: 2px;" size="20" v-text="'mdi-chevron-down'"></v-icon>
            <v-icon
              v-if="queryOptions.selectedChannelId.length"
              style="padding-top: 6px; margin-left: 5px;"
              class="pointer" small
              @click="queryOptions.selectedChannelId = []"
            >
              $close
            </v-icon>
          </template>
          <template v-slot:append-outer>
          </template>
          <template v-slot:selection>
          </template>
        </v-select>
      </template>
      <template v-slot:header.actions="">
        <span class="fz-13 fw-600 text-black">Actions</span>
      </template>
      <template v-slot:item.name="{ item }">
        <v-row class="ma-0 one-line align-center flex-nowrap">
          <v-avatar
            color="#A361DD"
            size="32"
            class="mr-2"
          >
            <img
              v-if="item.photoUrl"
              :src="item.photoUrl"
            >
            <span v-else class="fz-10 font-weight-bold white--text">{{ item.initials || '??' }}</span>
          </v-avatar>
          <span style="max-width: 200px;" class="fz-13 text-dgray one-line">{{ item.name ? item.name : item.email }}</span>
        </v-row>
      </template>
      <template v-slot:item.company="{ item }">
        <v-row class="ma-0 one-line align-center flex-nowrap">
          <span style="max-width: 200px;" class="fz-13 text-dgray one-line">{{ item.company ? item.company.name : '' }}</span>
        </v-row>
      </template>
      <template v-slot:item.createdAt="{ item }">
        <span class="one-line fz-13 text-dgray">{{ formatDate(item.createdAt) }}</span>
      </template>
      <template v-slot:item.ordersLtv="{ item }">
        <span class="one-line fz-13 text-dgray">${{ +item.ordersLtv | numberFormat }}</span>
      </template>
      <template v-slot:item.ordersCount="{ item }">
        <v-chip
            label
            color="#EEF2F7"
            small
            class="px-1 justify-center min-width-24"
          >
            <span class="one-line fz-13 text-dgray font-weight-regular">{{ item.ordersCount }} </span>
        </v-chip>
      </template>
      <template v-slot:item.channel="{ item }">
        <span v-if="item.channel" class="one-line fz-13 text-dgray">{{ item.channel.name }} </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row @click.stop class="ma-0">
          <v-chip
              v-if="item.facebook"
              label
              color="#EEF2F7"
              x-small
              style="height: 19px"
              class="px-1 mr-1 justify-center min-width-24 pointer chip-hover-yellow"
                @click="doCopy('Facebook', item.facebook)"
            >
              <span class="one-line fz-11 text-dgray font-weight-medium">Facebook</span>
          </v-chip>
          <v-chip
              v-if="item.instagram"
              label
              color="#EEF2F7"
              x-small
              style="height: 19px"
              class="px-1 mr-1 justify-center min-width-24 pointer chip-hover-yellow"
              @click="doCopy('Instagram', item.instagram)"
            >
              <span class="one-line fz-11 text-dgray font-weight-medium">Instagram</span>
          </v-chip>
          <v-chip
              v-if="item.telegram"
              label
              color="#EEF2F7"
              x-small
              style="height: 19px"
              class="px-1 mr-1 justify-center min-width-24 pointer chip-hover-yellow"
              @click="doCopy('Telegram', item.telegram)"
            >
              <span class="one-line fz-11 text-dgray font-weight-medium">Telegram</span>
          </v-chip>
          <v-chip
              v-if="item.email"
              label
              color="#EEF2F7"
              x-small
              style="height: 19px"
              class="px-1 justify-center min-width-24 pointer chip-hover-yellow"
              @click="doCopy('Email', item.email)"
            >
              <span class="one-line fz-11 text-dgray font-weight-medium">E-mail</span>
          </v-chip>
        </v-row>
      </template>
      <template v-slot:item.remove="{ item }">
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="370"
            content-class="rounded-xl"
            overlay-color="rgba(9, 24, 73, 0.2)"
            overlay-opacity="1"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              x-small
              icon
              fab
            >
              <v-icon size="20"  class="stroke-gray">$delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card class="pa-7">
              <v-card-text class="py-0">
                <div class="text-black font-weight-medium fz-18 text-center">Do you really want to delete Client: {{ item.name }}?</div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-space-between pa-0">
                <v-btn
                  class="rounded-lg text-none fz-16 fw-600"
                  outlined
                  width="150"
                  height="40"
                  @click="dialog.value = false"
                >Return</v-btn>
                <v-btn
                  elevation="0"
                  color="#FF0000"
                  width="150"
                  height="40"
                  class="rounded-lg white--text text-none fz-16 fw-600"
                  @click="deleteClient(item)"
                >
                  <v-icon left class="stroke-white">
                    $delete
                  </v-icon>
                  Delete</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'ClientTable',
  props: ['clients', 'table', 'queryOptions', 'sources'],
  filters: {
    numberFormat
  },
  data() {
    return {
      headers: [
        { text: 'Client name', value: 'name', filterable: false, class: 'table-icon-mb3', cellClass: 'pointer' },
        { text: 'Company', value: 'company', filterable: false, class: 'table-icon-mb3', cellClass: 'pointer' },
        { text: 'Added', value: 'createdAt', filterable: false, cellClass: 'pointer' },
        { text: 'LTV', value: 'ordersLtv', filterable: false, cellClass: 'pointer' },
        { text: 'Orders', value: 'ordersCount', filterable: false, cellClass: 'pointer' },
        { text: 'Source', value: 'channel', sortable: false, cellClass: 'pointer' },
        { text: 'Actions', value: 'actions', filterable: false, sortable: false, cellClass: 'pointer' },
        { text: '', value: 'remove', sortable: false, filterable: false, width: '50px', cellClass: 'pointer' }
      ],
      options: {
        itemsPerPage: 13
      }
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.$emit('addSnack', value, 'Success', action + ' value copied')
      }, function (e) {
        console.log(e)
      })
    },
    getClient(item) {
      this.$emit('getClient', item)
    },
    sortTable () {
      this.queryOptions.sortBy = null
      let title = ''
      if (this.options.sortBy.length) {
        if (this.options.sortBy[0] === 'ordersCount') title = 'ORDERS'
        else if (this.options.sortBy[0] === 'name') title = 'NAME'
        else if (this.options.sortBy[0] === 'company') title = 'COMPANY'
        else if (this.options.sortBy[0] === 'ordersLtv') title = 'LTV'
        else if (this.options.sortBy[0] === 'createdAt') title = 'ADDED'
        else if (this.options.sortBy[0] === 'channel') title = 'SOURCE'
        if (this.options.sortDesc[0] === true) title += '_DESC'
        else title += '_ASC'
      }
      this.queryOptions.sortBy = title
    },
    deleteClient (item) {
      this.$emit('deleteClient', item)
    }
  },
  watch: {
    options: {
      handler () {
        this.sortTable()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.status-select .v-input__append-inner, .status-select .v-select__slot {
  display: none;
}
</style>
