import { computed, ref } from 'vue'
import { useStore } from '@/store/index'

export default function countriesHook() {
  const sortTypes = [
    {
      text: 'Clients QTY',
      value: 'clientsCount'
    },
    {
      text: 'Orders Sum',
      value: 'ordersSum'
    }
  ]
  const selectedSortType = ref(sortTypes[0])

  const store = useStore()
  const searchCountry = ref('')
  const countries = computed(() => store.getters.getCountries ? store.getters.getCountries : [])

  if (!countries.value.length) {
    store.dispatch('fetchCountries')
  }

  const filteredCountries = computed(() => {
    console.log('Filtered')
    return countries.value.filter(el => el.name.toLowerCase().includes(searchCountry.value.toLowerCase())).sort((a, b) => {
      if (+a[selectedSortType.value.value] > +b[selectedSortType.value.value]) {
        return -1
      } else if (+a[selectedSortType.value.value] < +b[selectedSortType.value.value]) {
        return 0
      } else return 0
    })
  }, (ref) => {
    console.log(ref, 'ref')
  })

  function updateCountries() {
    store.dispatch('fetchCountries')
  }

  /* const sortByCounts = computed(() => {
    return countries.value.sort((a, b) => +a.clientsCount - +b.clientsCount)
  })
  const sortBySum = computed(() => {
    return countries.value.sort((a, b) => +a.ordersSum - +b.ordersSum)
  }) */
  return {
    searchCountry,
    countries,
    filteredCountries,
    selectedSortType,
    sortTypes,
    updateCountries
  }
}
